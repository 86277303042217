<template>
  <base-authentication
    title="Compte Datakairos"
    subtitle="Pour accéder au service Datakairos, veuillez remplir vos identifiants"
  >
    <form @submit.prevent="submitSignIn">
      <v-row>
        <v-col 
          cols="8"
          class="d-flex flex-column pb-0"
        >
          <form-fields-text
            v-model="email"
            type="email"
            placeholder="prenom.nom@unif.fr"
            :login-size="true"
            required
          />
          <form-fields-password
            v-model="password"
            placeholder="mot de passe"
            required
          />
        </v-col>
        <v-col 
          cols="4" 
          class="d-flex flex-column justify-end"
        >
          <div 
            v-for="(link, i) in linkList" 
            :key="i"
          >
            <base-link
              :icon="link.icon"
              :text="link.text"
              class="mb-8"
              @click="redirectForgotPassword"
            />
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col 
          cols="12"
          class="d-flex flex-column pt-0"
        >
          <base-checkbox
            v-model="checkboxValue"
            :label="'Ne pas enregistrer mon identifiant'"
            class="mb-3"
          />
          <auth-button-login type="submit" />
        </v-col>
      </v-row>
    </form>
  </base-authentication>
</template>

<script>
import FormFieldsText from '@/components/FormFieldsText.vue'
import FormFieldsPassword from '@/components/FormFieldsPassword.vue'
import AuthButtonLogin from '@/components/AuthButtonLogin.vue'
//import AuthButtonNewAccount from '@/components/AuthButtonNewAccount.vue'
import BaseLink from '@/components/BaseLink.vue'
import BaseCheckbox from '@/components/BaseCheckbox.vue'
import { mapFields } from 'vuex-map-fields';
import { mapActions, mapMutations } from 'vuex';
import BaseAuthentication from '@/components/BaseAuthentication.vue'

export default {
  name: "Login",
  components: {
    FormFieldsText,
    FormFieldsPassword,
    AuthButtonLogin,
    BaseLink,
    BaseCheckbox,
    BaseAuthentication
  },
  data() {
    return {
      linkList: [
        {
          icon: "$passwordLost",
          text: "Mot de passe perdu ?"
        },
      ],
      checkboxValue: false,
    }
  },
  computed: mapFields('authentication', ['signInForm.email', 'signInForm.password']),
  created() {
    const { origin } = new URL(process.env.VUE_APP_API_URL);
    if (window.parent) {
      window.parent.postMessage('loaded', '*');
    }
    window.addEventListener('message', (e) => {
      if (e.origin === origin && e.data.Authorization) {
        localStorage.authorization = e.data.Authorization;
        window.location.href = '/';
      }
    });
  },
  methods: {
    ...mapActions('authentication', ['signIn']),
    ...mapMutations(['setAlert']),
    submitSignIn() {
      this.signIn().then(() => {
        const introValue = localStorage.getItem('intro')
        if(introValue == 'true'){
          this.$router.push('/profile/person');
        } else {
          this.$router.push('/introduction');
        }
      }).catch(() => {
        this.setAlert({
          show: true,
          text: 'Identifiants incorrects',
        })
      });
    },
    redirectForgotPassword() { 
      this.$router.push('/forgot-password');
    }
  }
}
</script>