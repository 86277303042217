<template>
  <div>
    <a 
      @click="emitClick"
    >
      <v-icon 
        class="mr-1"
        v-text="icon" 
      />
      <span class="text-decoration-underline text-caption cassiopee-grey--text text--darken-1">
        {{ text }}
      </span>
    </a>
  </div>
</template>

<script>
export default {
  name: "BaseLink",
  props: {
    icon: {
      type: String,
      default: ""
    },
    text : {
      type: String,
      required: true
    }
  },
  methods: {
    emitClick() {
      this.$emit('click')
    },
  }
}
</script>