<template>
  <div class="d-flex">
    <v-simple-checkbox
      v-bind="$attrs"
      class="mt-0 pt-0"
      color="cassiopee-purple"
      :ripple="false"
      v-on="$listeners"
    />
    <span class="text-subtitle-2 cassiopee-grey--text text--darken-1 my-auto"> {{ label }} </span>
  </div>
</template>

<script>
export default {
  name: "BaseCheckbox",
  props: {
    label: {
      type: String,
      required: true
    },
  }
}
</script>