<template>
  <div class="text-center">
    <base-button
      right
      x-small
      icon="$arrowRight"
      v-bind="$attrs"
      width="645px"
    >
      Se connecter
    </base-button>
  </div>
</template>

<script>
import BaseButton from './BaseButton.vue';

export default {
  name: "AuthButtonLogin",
  components: {
    BaseButton,
  },
};
</script>