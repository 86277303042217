<template>
  <base-form-field
    :label="label"
    v-bind="$attrs"
  >
    <v-text-field
      :value="value"
      :placeholder="placeholder"
      :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
      :type="show ? 'text' : 'password'"
      class="form-input-login text-caption"
      color="cassiopee-purple"
      height="50"
      outlined
      :rules="rules"
      v-bind="$attrs"
      @click:append="show = !show"
      @input="$emit('input', $event)"
    />
  </base-form-field>
</template>

<script>
import BaseFormField from '@/components/BaseFormField.vue'

export default {
  name: "FormFieldsPassword",
  components: {
    BaseFormField 
  },
  props: {  
    placeholder: {
      type: String,
      default: ""
    },
    value: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: ""
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      show: false,
    }
  }
}
</script>